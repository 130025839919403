import Image from 'next/image';
import Link from 'next/link';
export const AuthBtn = () => {
  return (
    <Link href='/login' className='z-10 flex w-fit cursor-pointer flex-row items-center gap-2' title='Login'>
      <div className='relative size-6'>
        <Image
          src='/images/user-profile.png'
          alt='profile icon'
          sizes='24px'
          fill
          className='mx-[6px] size-6 object-contain'
        />
      </div>
    </Link>
  );
};
