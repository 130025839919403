// format price $580.00 USD

export const formatPrice = (price?: number | string) => {
  if (!price) {
    return '';
  }
  //   if string, convert to number
  const priceNum = typeof price === 'string' ? parseFloat(price) : price;
  return priceNum.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' USD';
};
