import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { CartApi } from '@/api-client/cart';
import { LOCAL_LOCATION } from '@/constants';
import { useAuth } from '@/hooks/auth.hook';
import useLocationStore from '@/store/locationStore';
import { notification } from 'antd';

export const useCart = () => {
  const { profile } = useAuth();
  const queryClient = useQueryClient();
  const { location } = useLocationStore() as any;

  // Member cart query
  const cartMemberQuery = useQuery({
    queryKey: ['cart-user', LOCAL_LOCATION, location],
    queryFn: () => CartApi.getMemberCart(location?.id || LOCAL_LOCATION || ''),
    staleTime: 60,
    gcTime: 120,
    refetchOnWindowFocus: false,
    enabled: !!profile && (!!location?.id || !!LOCAL_LOCATION), // Enabled only if the user is authenticated
  });

  // Guest cart query
  const cartGuestQuery = useQuery({
    queryKey: ['cart-guest', LOCAL_LOCATION, location],
    queryFn: () => CartApi.getGuestCart(location?.id || LOCAL_LOCATION || ''),
    staleTime: 60,
    gcTime: 120,
    refetchOnWindowFocus: false,
    enabled: !profile && (!!location?.id || !!LOCAL_LOCATION), // Enabled only if the user is a guest
  });

  // Mutation to delete a guest cart item
  const deleteCartGuestMutation = useMutation({
    mutationFn: CartApi.deleteGuestCartItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart-guest'] });
    },
    onError: (err: any) => {
      notification.error({ message: 'Service', description: `Remove Fail - ${err}` });
    },
  });

  // Mutation to delete a member cart item
  const deleteCartMemberMutation = useMutation({
    mutationFn: async id => {
      return CartApi.deleteMemberCartItem(id, LOCAL_LOCATION);
    }, // Define this function in your CartApi
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart-user'] }); // Invalidate member cart query to refresh data
      notification.success({ message: 'Service', description: `Remove Success` });
    },
    onError: (err: any) => {
      notification.error({ message: 'Service', description: `Remove Fail - ${err}` });
    },
  });

  return {
    cartMemberQuery,
    cartGuestQuery,
    deleteCartGuestMutation,
    deleteCartMemberMutation,
  };
};
