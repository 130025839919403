'use client';
import { Button, Modal } from 'antd';
import Image from 'next/image';
import { useState } from 'react';
import ArrowRight from '../../../../../public/images/ArrowRight.png';
import MembershipDetail from '../../../../../public/images/membership/membership-detail.webp';
import MembershipCarousel from '../../Carousel/MembershipCarousel';

const MembershipSection = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleButtonClick = () => {
    setIsPopupVisible(true); // Show the popup
  };

  const handleClose = () => {
    setIsPopupVisible(false); // Hide the popup
  };

  return (
    <>
      <div className='flex h-full flex-col items-center overflow-hidden bg-home-product bg-cover bg-center bg-no-repeat py-8 sm:pb-[90px] sm:pt-[83px]'>
        <div className='flex flex-col items-center justify-center gap-[10px] *:text-white sm:gap-8'>
          <span className='font-[hneu] text-base leading-tight sm:text-2xl sm:leading-[28px]'>
            The membership cards of your choice
          </span>
          <div className='hidden h-1 w-[48px] border-b border-white sm:block'></div>
          <hr className='mx-auto mt-[6px] h-0.5 w-12 border-0 bg-white sm:hidden' />

          <span className='title text-center font-[didot] text-[32px] font-bold capitalize leading-tight sm:text-[64px] md:leading-[80px]'>
            Special Offers for Only Members
          </span>
        </div>

        <MembershipCarousel />

        <Button
          size='large'
          onClick={handleButtonClick} // Add onClick to open the modal
          className='group !mx-8 !my-[12px] !mt-8 flex !border-none !bg-zelene-cream !px-6 !py-3 font-hneu !text-zelene-blue !transition-colors hover:!bg-zelene-red hover:!text-white sm:!mt-4'
        >
          Explore more
          <span className='relative size-5'>
            <Image
              className='object-contain transition-transform group-hover:translate-x-2 group-hover:scale-x-110'
              alt='arrow right'
              src={ArrowRight}
              sizes='20px'
              fill
            />
          </span>
        </Button>
      </div>

      {/* Modal or Popup */}
      {isPopupVisible && (
        <Modal
          open={isPopupVisible}
          onCancel={handleClose} // Close the popup
          footer={null} // No footer buttons
          centered // Center the popup
          className='custom-popup-modal'
          width={1000}
        >
          <Image
            src={MembershipDetail} // Replace with your image path
            alt='Membership Detail'
            width={1800} // Adjust the size
            height={1600} // Adjust the size
            className='popup-image'
          />
        </Modal>
      )}
    </>
  );
};

export default MembershipSection;
