'use client';

import { Carousel } from 'antd';
import { useRef, useState } from 'react';
import Slider2 from '../Slider2';

const CarouselSlider = () => {
  const ref: any = useRef();
  const [nextIndex, setNextIndex] = useState(0);

  const beforeChange = (prev: number, next: number) => {
    setNextIndex(next);
  };

  const rightClick = () => {
    ref?.current?.next();
  };

  const leftClick = () => {
    ref?.current?.prev();
  };

  return (
    <Carousel
      ref={ref}
      beforeChange={beforeChange}
      dots={false}
      draggable
      initialSlide={0}
      className='!z-30'
      rootClassName='!z-30'
      autoplay
      autoplaySpeed={7000}
      infinite
    >
      {/* <div className='h-[600px] overflow-hidden pt-[116px] sm:!h-[1000px] sm:pt-[122px]'>
    <HeaderHero />
    <BackgroundText />
  </div> */}

      <Slider2
        rightClick={rightClick}
        leftClick={leftClick}
        ref={ref}
        description={
          'Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift card.'
        }
        textMain={'Giftcard is'}
        textMainSecond={'Available'}
        imgMockup={'/images/Home/MainHero2.webp'}
        imgSlider={'/images/Home/MockupSlider2.webp'}
        textSlider={'1 - 2'}
        textCta={'Gift Now'}
        linkCta={'/gift'}
      />
      <Slider2
        rightClick={rightClick}
        leftClick={leftClick}
        ref={ref}
        description={
          'Customize your Head Spa experience by selecting additional premium services designed to ensure your experience is suited specifically to you.'
        }
        textMain={'Featured'}
        textMainSecond={'Services'}
        imgMockup={'/images/Home/Slider3.webp'}
        imgSlider={'/images/Home/Slider3.png'}
        textSlider={'2 - 2'}
        textCta={'Book Now'}
        linkCta={'/booking'}
      />
    </Carousel>
  );
};

export default CarouselSlider;
