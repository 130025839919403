'use client';

import { Tabs, TabsProps } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';
import GoogleIcon from '../../../../../public/images/Home/GoogleIcon.webp';
import MainTestimonial from '../../../../../public/images/testinomial-main.png';
import VectorTesti from '../../../../../public/images/VectorTesti.png';
import AutoSlide from '../HeroSection/AutoSlide';

const TestinomialSection = () => {
  const [activeKey, setActiveKey] = React.useState(1);
  const activeStyle =
    'flex h-[85px] w-[85px] items-center justify-center rounded-full border !border-red-900 transition-all';

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <div className={clsx(activeKey === 1 && activeStyle)}>
          <div className='flex h-[64px] w-[64px] items-center justify-center rounded-full bg-slate-200'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='customer-review'
            />
          </div>
        </div>
      ),

      children: (
        <div className='flex flex-col font-hneu'>
          <p>10/10 Experience at Hera Head Spa!</p>
          <p>
            As someone in the beauty industry, I hold incredibly high standards for any service I receive, and Hera Head
            Spa exceeded every expectation. From the moment I walked in, I was met with a calming, serene environment
            that instantly made me feel like I was in the right place for some much-needed rejuvenation.
          </p>

          <p>
            The level of care, professionalism, and expertise from the team was beyond impressive. They truly understand
            not only the technical aspects of scalp and hair health but also how to deliver an experience that leaves
            you feeling completely restored. The products they use are top-tier, and the attention to detail was
            something I rarely see, even in my own field. Every step of the process felt luxurious, intentional, and
            perfectly tailored to my needs.
          </p>

          <p>
            I left with my scalp feeling invigorated and my hair looking and feeling its absolute best. Hera Head Spa
            offers a level of service that sets a new standard for the industry. If you're looking for a transformative
            experience, this is the place.
          </p>

          <p>
            As someone who knows what excellence looks like in beauty and wellness, I can confidently say Hera Head Spa
            is a 10/10 and worth every bit of praise!"
          </p>

          <p>
            Would you like me to analyze any particular aspects of this review or discuss what makes it especially
            effective as a testimonial?
          </p>

          <p className='font-hneu text-[16px] font-medium leading-[20px]'>Kari Patterson</p>
          {/* <span className='text-sm leading-[17px] text-[#A7A7A7]'>CEO & Founder</span> */}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={clsx(activeKey === 2 && activeStyle)}>
          <div className='flex h-[64px] w-[64px] items-center justify-center rounded-full bg-slate-200'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='review comment'
            />
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col'>
          <p>
            Wonderful experience with Eric and Daniel! Thank you both for your gentle and relaxing experience!
            Thoroughly enjoyed and will be back!
          </p>

          <p className='text-[16px] font-medium leading-[20px]'>Nancy Mijares</p>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div className={clsx(activeKey === 3 && activeStyle)}>
          <div className='flex h-[64px] w-[64px] items-center justify-center rounded-full bg-slate-200'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='review comment'
            />
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col'>
          <p>
            From the moment you walk in we were very well taken care of. We got the Elite Scalp package and I have zero
            regrets….my scalp feels squeaky clean now!! We will definitely come back.
          </p>

          <p className='text-[16px] font-medium leading-[20px]'>Tammy Tran</p>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className={clsx(activeKey === 4 && activeStyle)}>
          <div className='flex h-[64px] w-[64px] items-center justify-center rounded-full bg-slate-200'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='review comment'
            />
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col'>
          <p>
            I recently visited Zelene Head Spa, and it was an exceptional experience from start to finish. The moment I
            walked in, I was greeted by a calming ambiance and friendly staff who made me feel immediately at ease. The
            head spa treatment was incredibly relaxing, and the staff were attentive and professional, clearly skilled
            in creating a rejuvenating experience. The attention to detail was impressive—the gentle scalp massage, the
            soothing products, and the overall atmosphere made it a truly therapeutic visit. My hair felt soft and
            revitalized afterward, and I left the spa feeling refreshed and stress-free. If you’re looking for a
            high-quality head spa experience in Frisco, I highly recommend Zelene Head Spa. It’s the perfect place to
            unwind and treat yourself.
          </p>

          <p className='text-[16px] font-medium leading-[20px]'>Nhi Nguyen</p>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div className={clsx(activeKey === 5 && activeStyle)}>
          <div className='flex h-[64px] w-[64px] items-center justify-center rounded-full bg-slate-200'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='review comment'
            />
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col'>
          <p>
            Today marks their grand opening, and they are giving out numerous freebies. I decided to try the Elite Scalp
            package, and it was fantastic. If you have experienced Hera Head Spa before, I can confidently say that
            Zelene is even more luxurious. The service, treatment, and venue are definitely worth the money you spend. I
            highly recommend it: 100%!
          </p>

          <p className='text-[16px] font-medium leading-[20px]'>Luke Chau</p>
        </div>
      ),
    },
  ];

  const onChange = (key: string) => {
    setActiveKey(+key);
  };

  return (
    <div className='relative flex flex-col overflow-hidden bg-[#FFFDF980] pb-0 pt-10 sm:py-[128px]'>
      <div className='absolute right-0 top-0 z-[-1] h-1/3 w-[min(676px,70%)]'>
        <Image alt='vector' src={VectorTesti} fill sizes='40vw' className='object-contain' />
      </div>

      <div className='mx-auto flex w-full flex-col px-6 *:flex-1 sm:w-[min(1512px,90%)] sm:px-0 lg:flex-row'>
        <div className='relative !mx-auto !flex max-h-[527px] min-h-[320px] w-full !flex-1 !items-center !justify-center md:h-[527px] md:w-[526px]'>
          <Image
            fill
            sizes='(min-width: 640px) 80vw,50vw'
            src={MainTestimonial}
            alt='MainTestimonial'
            className='max-h-[527px] object-contain sm:max-w-[526px]'
          />
        </div>

        <div className='flex flex-1 flex-col gap-6'>
          <div className='flex w-full items-center gap-4 pt-8 font-[hneu] text-[20px] !leading-[24px] text-zelene-blue sm:pt-0'>
            <div className='h-0.5 w-[93px] bg-[#1D3F75] sm:h-1'></div>
            <span className='text-base font-medium leading-tight sm:text-2xl'>Testimonials</span>
          </div>

          {/* <div className='max-w-[615px] font-didot text-[32px] font-semibold leading-[40px] text-zelene-blue sm:text-[64px] sm:leading-[80px]'> */}
          {/* <div className='w-full font-[wise] text-[40px] leading-[48px] text-zelene-blue sm:max-w-[452px]'> */}
          <div className='font-[didot] text-[clamp(2.5rem,0.2941rem_+_3.0216vw,4rem)] font-bold leading-[40px] text-zelene-blue sm:leading-tight'>
            <span className='w-full text-[32px] sm:!max-w-[615px] sm:text-[40px]'>
              What Our Customers <br className='sm:hidden' />
              Say About Us
            </span>
          </div>

          <Tabs translate='yes' defaultActiveKey='1' items={items} onChange={onChange} />
        </div>
      </div>

      <div className='relative mx-auto mb-4 mt-10 flex w-full max-w-[2040px] flex-col sm:mb-0'>
        {/* <div className='z-10 h-full w-full bg-tagline-1 bg-cover bg-center bg-no-repeat' />
        <div className='absolute h-full w-full bg-tagline-2 bg-cover bg-center bg-no-repeat' /> */}

        <div className='z-20 -translate-x-1 rotate-[-5deg]'>
          <AutoSlide backgroundColor='bg-zelene-blue' className='w-[110%]' />
        </div>
        <div className='z-10 -translate-x-1 rotate-[3deg]'>
          <AutoSlide backgroundColor='bg-zelene-heavy-cream' className='w-[110%]' />
        </div>
      </div>
    </div>
  );
};

export default TestinomialSection;
