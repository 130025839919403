'use client';

import { ServiceApi } from '@/api-client/service';
import ClientButton from '@/components/elements/Button/ClientButton';
import { LOCAL_LOCATION } from '@/constants';
import axiosClient from '@/helper/call-center';
import { formatPrice } from '@/utils/format-fn';
import { useQuery } from '@tanstack/react-query';
import { Carousel, Tooltip } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React, { useRef, useState } from 'react';
import ImageAvatar from '../../../../public/images/Home/ImageCarousel.png';

const items = Array.from({ length: 12 }, (_, index) => (
  <div key={index} className='-mx-3 !flex select-none !rounded-tr-[64px] px-3'>
    <div className='!rounded-bl-[64px] border-y border-l-[1px] !border-zelene-heavy-cream !border-r-[none] !bg-zelene-beige'>
      <Image
        src={ImageAvatar}
        alt='mock service avatar'
        width={111}
        height={179}
        className='h-full w-[111px] !rounded-bl-[64px] !rounded-tr-[50px] !border-none !bg-zelene-beige object-contain'
      />
    </div>

    <div className='flex w-full flex-col justify-center !rounded-tr-[64px] border-y border-r-[1px] !border-zelene-heavy-cream !bg-zelene-beige pl-3 *:self-start'>
      <span className='select-none pb-[6px] font-hneu text-sm leading-tight'>150 mins</span>
      <span className='max-w-[150px] pb-4 font-hneu text-[16px] font-medium leading-[19px] text-[#102647]'>
        Elite Crown Haven
      </span>
      <span className='select-none font-hneu text-base font-medium text-zelene-red'>$ 580.00</span>
    </div>
  </div>
));

const settings = {
  dots: false,
  infinite: true, // Set to true for continuous autoplay
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: false,
  responsive: [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 768, settings: { slidesToShow: 2.3 } },
    { breakpoint: 640, settings: { slidesToShow: 1 } },
  ],
};

const HomeServiceCarousel = () => {
  const ref: any = useRef();
  const [services, setServices] = useState<any[]>([]);
  const [nextIndex, setNextIndex] = React.useState(0);
  const [loading, setLoading] = useState(true);

  const locationId = LOCAL_LOCATION || '';

  const serviceQuery = useQuery({
    queryKey: ['service', locationId],
    queryFn: () => ServiceApi.getMoreServiceByLocation(locationId),
    staleTime: 60,
    gcTime: 120,
    refetchOnWindowFocus: false,
    enabled: !!locationId && locationId !== undefined,
  });
  const serviceData = serviceQuery.data?.data || [];

  const getServicesQuery = useQuery({
    queryKey: ['service', serviceData._id],
    queryFn: () => axiosClient.get(`/service/find-by-category-and-branch/${serviceData?._id}/${locationId}`),
    staleTime: 60,
    gcTime: 120,
    refetchOnWindowFocus: false,
    enabled: !!serviceData?._id && !!locationId && locationId !== undefined,
  });

  // console.log('getServicesQuery', getServicesQuery?.data?.data);

  const allServices: any = (getServicesQuery?.data?.data as any) || [];

  // console.log('allServices allServicesallServicesallServices', allServices);

  // // Fetch services from API
  // useEffect(() => {
  //   if (!!serviceData._id) {
  //     console.log('firstfirst', !!serviceData._id);
  //     const fetchServices = async () => {
  //       try {
  //         const locationId = localStorage.getItem('location_id'); // Retrieve location from localStorage
  //         const response = await axiosClient.get(
  //           `/service/find-by-category-and-branch/${serviceData?._id}/${locationId}`,
  //         );

  //         setServices(response.data || []); // Set the services data
  //         setLoading(false); // Set loading to false once data is fetched
  //       } catch (error) {
  //         console.error('Error fetching services:', error);
  //         setLoading(false);
  //       }
  //     };
  //     fetchServices();
  //   }
  // }, [locationId]);

  const beforeChange = (prev: number, next: number) => {
    setNextIndex(next);
  };

  const rightLeft = nextIndex !== 0;
  const rightNext = nextIndex !== items?.length - ref?.current?.innerSlider?.props?.slidesToShow;
  if (getServicesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='!z-[50] mx-auto w-[min(1512px,100%)]'>
      <Carousel
        // afterChange={afterChange}
        beforeChange={beforeChange}
        ref={ref}
        {...settings}
        infinite={true}
        initialSlide={1}
      >
        {allServices
          ?.filter((item: any) => item.allow_customer_view === true)
          ?.map((service: any) => {
            return (
              <Link
                key={service._id}
                href={{
                  pathname: `/services/service-detail`, // Service details page
                  query: { q: service._id }, // Pass query params if needed
                }}
                passHref
                className=''
              >
                <div
                  key={service._id}
                  className='mx-auto !flex max-h-[179px] w-[90%] select-none !rounded-tr-[64px] lg:max-h-[179px] lg:max-w-[350px]'
                >
                  <div className='!rounded-bl-[64px] border-y border-l-[1px] !border-zelene-heavy-cream !border-r-[none] !bg-zelene-beige'>
                    <div className='relative h-[179px] w-[130px] lg:!h-[179px]'>
                      <Image
                        src={service.image ? `/service/${service.image}` : ImageAvatar} // If no image, fallback to default
                        alt='Service avatar'
                        fill
                        className='h-full !rounded-bl-[64px] !rounded-tr-[50px] !border-none !bg-zelene-beige object-cover'
                      />
                    </div>
                  </div>

                  <div className='flex w-full flex-col justify-center !rounded-tr-[64px] border-y border-r-[1px] !border-zelene-heavy-cream !bg-zelene-beige pl-6 *:self-start sm:pl-3'>
                    <span className='select-none py-2 pb-[6px] font-hneu text-sm font-normal leading-tight text-zelene-dark-blue'>
                      {service.duration} mins
                    </span>
                    <div className='!max-w-[190px] pb-4 text-left font-hneu text-[16px] font-medium leading-[19px] text-zelene-dark-blue'>
                      <span className='line-clamp-2'>{service.name}</span>
                    </div>
                    <span className='select-none font-hneu text-base font-medium text-zelene-red'>
                      {formatPrice(service.price)}
                    </span>
                  </div>
                </div>
              </Link>
            );
          })}
      </Carousel>

      <div className={clsx('flex w-full justify-end px-5 pt-4', rightLeft && 'justify-between')}>
        {rightLeft && (
          <Tooltip title='Previous'>
            <ClientButton position='left' onClick={() => ref.current.prev()} />
          </Tooltip>
        )}

        {rightNext && (
          <Tooltip title='Next'>
            <ClientButton position='right' onClick={() => ref.current.next()} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default HomeServiceCarousel;
