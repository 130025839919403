'use client';

import { useRouter } from '@/hooks/useRouter';

export const items = [
  {
    title: 'ESSENTIAL',
    price: 'from $99',
    bgClass: 'bg-product-card1',
    isBestSeller: false,
  },
  {
    title: 'PREMIUM',
    price: 'from $149',
    bgClass: 'bg-product-card2',
    isBestSeller: false,
  },
  {
    title: 'ELITE',
    price: 'from $199',
    bgClass: 'bg-product-card3',
    isBestSeller: false,
  },
  {
    title: 'FAMILY & FRIEND',
    price: 'from $299',
    bgClass: 'bg-product-card4',
    isBestSeller: true,
  },
  // Add more items as needed
];

const MembershipCarousel = () => {
  const router = useRouter();

  // const settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   draggable: true, // Enable dragging
  //   responsive: [
  //     { breakpoint: 1024, settings: { slidesToShow: 3 } },
  //     { breakpoint: 768, settings: { slidesToShow: 1 } },
  //   ],
  // };
  const handleClick = () => {
    console.log('clicked');
  };
  return (
    <div className='mx-auto mt-6 w-full max-w-screen-2xl px-6 sm:px-12'>
      {/* <Carousel {...settings}>
         
        </Carousel> */}
      <div className='grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4'>
        {items.map((item, index) => (
          <div key={index} className='flex justify-center' onClick={handleClick}>
            <div className='flex flex-1 flex-col items-center justify-center gap-2'>
              <div
                className={`relative min-h-[186px] w-full rounded-[44px] !border-2 !border-[#FCDCAB] bg-slate-200 sm:rounded-3xl md:h-[500px] md:max-w-[300px] ${item.bgClass} cursor-pointer bg-cover bg-center bg-no-repeat`}
              >
                {item.title === 'ELITE' && <div className='relative -right-0 -top-12 size-20'></div>}
                {
                  item.isBestSeller && (
                    <div className='absolute right-4 top-11 -translate-y-1/2 translate-x-1/2 sm:right-0 sm:top-0'>
                      <ComponentBestSeller />
                    </div>
                  ) // Display the best seller badge
                }
              </div>
              <span className='pt-2 font-hneu text-[18px] font-medium leading-[21px] text-white [text-shadow:0px_4px_14px_rgba(0,_0,_0,_0.20)]'>
                {item.title}
              </span>
              <span className='pt-2 font-hneu text-base font-normal leading-[21px] text-white [text-shadow:0px_4px_14px_rgba(0,_0,_0,_0.20)]'>
                {item.price}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ComponentBestSeller = () => {
  return (
    <div
      className='inline-flex h-[70px] w-[70px] items-center justify-center rounded-full border-4 border-[#1D3F75] bg-cover bg-center sm:h-[100px] sm:w-[100px]'
      style={{
        backgroundImage: "url('/images/Home/image_best_seller.png')",
      }}
    >
      <span className='text text-center font-[wise] text-sm font-medium text-white sm:text-xl'>
        BEST
        <br />
        SELLER
      </span>
    </div>
  );
};
export default MembershipCarousel;
