'use client';

// import { Carousel } from 'antd';
import { Carousel, CarouselContent, CarouselItem } from '@/components/ui/carousel';
import clsx from 'clsx';
import Image from 'next/image';
import { useMemo } from 'react';
import PoliciesList1 from '../../../../public/images/Location/Carousel/Frisco Staff Group Pic.webp';
import PoliciesList4 from '../../../../public/images/Location/Carousel/Frisco.webp';
import PoliciesList2 from '../../../../public/images/Location/Carousel/FriscoSparoom.webp';
import PoliciesList3 from '../../../../public/images/Location/Carousel/Waiting area frisco.webp';
const LocationCarousel = () => {
  const listPolicies = useMemo(() => [PoliciesList1, PoliciesList2, PoliciesList3, PoliciesList4], []);

  return (
    <>
      <div className='hidden w-full flex-col items-center gap-8 pt-16 sm:flex sm:flex-row'>
        {listPolicies.map((item, index) => {
          return (
            <div
              className={clsx(
                'relative h-[507px] w-[80%] sm:w-full',
                index % 2 === 0 && 'sm:h-[345px]',
                (index === 0 || index === 3) && 'sm:w-[70%]',
              )}
              key={index}
            >
              <Image
                src={item}
                alt='Policies image'
                className={clsx(
                  'w-full rounded-[44px] object-cover object-center',
                  index === 0 && 'sm:rounded-bl-none sm:rounded-tl-none',
                  index === 3 && 'sm:rounded-br-none sm:rounded-tr-none',
                )}
                fill
                sizes='(min-width: 640px) 80vw,50vw'
              />
            </div>
          );
        })}
      </div>
      {/* mobile */}
      <div className='mt-16 h-[300px] w-full sm:hidden'>
        {/* <Carousel initialSlide={1} draggable infinite arrows dotPosition='bottom' slidesToScroll={1}>
          {[PoliciesList1, PoliciesList2, PoliciesList3, PoliciesList4].map((item, index) => {
            return (
              <div className='relative h-[300px] w-full' key={index}>
                <Image
                  src={item}
                  alt='Policies image'
                  fill
                  className='rounded-2xl object-contain'
                  sizes='(min-width: 640px) 80vw,0vw'
                />
              </div>
            );
          })}
        </Carousel> */}
        <Carousel
          typeTween='opacity'
          // opts={{
          //   loop: true,
          // }}
        >
          <CarouselContent>
            {listPolicies.map((item, index) => {
              return (
                <CarouselItem key={index} className='flex-[0_0_78%]'>
                  <div className='relative h-[280px] w-full' key={index}>
                    <Image
                      src={item}
                      alt='Policies image'
                      fill
                      className='rounded-2xl object-contain'
                      sizes='(min-width: 640px) 80vw,0vw'
                    />
                  </div>
                </CarouselItem>
              );
            })}
          </CarouselContent>
        </Carousel>
      </div>
    </>
  );
};

export default LocationCarousel;
