import { IMembership, IProfile } from '@/interface/auth';
import { ITableAddItem } from '@/interface/table-add-item';
import { notification } from 'antd';
import Compressor from 'compressorjs';
import moment from 'moment';
import { UserRoleKey } from './enum';

export function getFirstWord(input: string): string {
  const words = input.trim().split(/\s+/);
  return words.length > 0 ? words[0] : '';
}

export function getQueryParams(): Record<string, string> {
  const params = new URLSearchParams(window.location.search);
  const result: Record<string, string> = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}

export const imgUrlTransferV1 = (url: string) => {
  if (url === 'member-default.jpg') {
    return '/images/member-default.jpg';
  }
  if (url) return `/service/${url}`;
  return url;
};

export const downFile = (fullPath: string, fileName: string) => {
  fetch(fullPath)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want

      document.body.appendChild(a);
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('An error sorry'));
};

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const parserQueryParams = (queryParams: IterableIterator<[string, string]>) => {
  const result: { [key: string]: string } = {};

  let isDone = false;

  while (!isDone) {
    const { value, done } = queryParams.next();

    if (!Boolean(value)) {
      continue;
    }
    result[value[0]] = value[1];

    isDone = Boolean(done);
  }

  return result;
};

export const detectDevice = (userAgent: any) => {
  let os: { name: string; version: string } | null = null;
  let browser: { name: string; version: string } | null = null;
  let device: string | null = null;

  const osRegex = /(windows nt|mac os x|linux|ubuntu|iphone|ipad|android) ?([\d._]*)/i;
  const osMatch = userAgent.match(osRegex);

  if (osMatch) {
    const osName = osMatch[1];
    const osVersion = osMatch[2].replace(/_/g, '.');
    os = { name: osName, version: osVersion };
  }

  const browserRegex = /(chrome|firefox|safari|opera|edge|msie)\/([\d\.]+)/i;
  const match = userAgent.match(browserRegex);

  if (match) {
    const browserName = match[1];
    const browserVersion = match[2];

    browser = { name: browserName, version: browserVersion };
  }
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Mobile|Tablet/i;
  const isMobile = mobileRegex.test(userAgent);

  if (isMobile) {
    device = 'mobile';
  } else {
    device = 'desktop';
  }

  return { os, browser, device };
};

export function isValidEmail(email: string): boolean {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

export function capitalizeFirstLetter(str: string): string {
  if (typeof str !== 'string' || str?.length === 0) {
    return '';
  } else {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }
}

export async function capitalizeFirstLetterPromise(str: string): Promise<string> {
  const value = await str;

  if (typeof value !== 'string' || value.length === 0) {
    return '';
  } else {
    return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
  }
}

export const detectIp = (ipAddress: string) => {
  const ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;

  if (ipAddress && ipRegex.test(ipAddress)) {
    return ipAddress;
  } else {
    return null;
  }
};

export function encodeURIWidthSpecialCharacters(s: string): string {
  return encodeURIComponent(s)
    .replace(/[-_.!~*'()]/g, char => `%${char.charCodeAt(0).toString(16).toUpperCase()}`)
    .replace(/%20/g, '+');
}

export function decodeURIWidthSpecialCharacters(s: string): string {
  return decodeURIComponent(s.replace(/\+/g, '%20'));
}

export const checkIsNumber = (value: string | undefined) => {
  return value && !Number.isNaN(Number(value)) && Number.isInteger(Number(value)) ? Number(value) : undefined;
};

export function generateURLWithQueryParams(url: string, queryParamsObject: { [key: string]: string | undefined }) {
  const keys = Object.keys(queryParamsObject);
  const query = keys.map(key => `${key}=${queryParamsObject[key]}`).join('&');

  return `${url}?${query}`;
}

export const getCountPage = (lenngthIteam: number, numSplit: number) => {
  const x = lenngthIteam / numSplit;
  const y = Math.round(lenngthIteam / numSplit);

  return y - x < 0 ? y + 1 : y;
};

export const formatNumber = (n: number | string) => {
  return parseInt(n.toString()) > 9 ? `${parseInt(n.toString())}` : `0${parseInt(n.toString())}`;
};

export const getDateTime = (a: string | null) => {
  if (!a) return '';
  const d = new Date(a);

  return `${formatNumber(d.getHours())}:${formatNumber(d.getMinutes())} ${formatNumber(d.getDate())}/${formatNumber(
    d.getMonth() + 1,
  )}/${formatNumber(d.getFullYear())}`;
};

export const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|\[\];:,\.<>?]).{8,}$/;
// export const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

export function getLastWord(sentence: string): string {
  const words = sentence.trim().split(/\s+/);
  return words.length > 0 ? words[words.length - 1] : '';
}

export function formatTimeWithAMPM(timeString: string) {
  const [hour, minutes] = timeString.split(':').map(Number);
  const suffix = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 || 12;

  return minutes === 0
    ? `${formattedHour}:00 ${suffix}`
    : `${formattedHour}:${minutes < 10 ? '0' : ''}${minutes} ${suffix}`;
}

export function convertTime(time: any) {
  const hours = parseInt(time.hour);
  const minutes = time.minutes;
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  const converHours = hours % 12 || 12;

  return minutes === '0' ? `${converHours} ${period}` : `${converHours}:${minutes} ${period}`;
}

export function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const generateRandomPassword = () => {
  const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';
  const allChars = upperChars + lowerChars + numbers + specialChars;

  let password = '';

  password += upperChars.charAt(Math.floor(Math.random() * upperChars.length));
  password += lowerChars.charAt(Math.floor(Math.random() * lowerChars.length));
  password += numbers.charAt(Math.floor(Math.random() * numbers.length));
  password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

  const totalLength = Math.floor(Math.random() * 3) + 8;

  for (let i = password.length; i < totalLength; i++) {
    password += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  return password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');
};

export function formatNumberWithCommas(value: number): string {
  try {
    const number = Number(value);
    return number.toLocaleString('en-US');
  } catch (error) {
    return value.toString();
  }
}

export function formatNumberPage(number: number) {
  const formattedNumber = new Intl.NumberFormat().format(number);
  let numberFormat = formattedNumber.toString().replaceAll('.', ',');
  return numberFormat ?? '0';
}

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  // Loại bỏ tất cả các ký tự không phải là số
  const phoneNumber = value.replace(/[^\d]/g, '');

  // Format theo dạng (xxx) xxx xxxx
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 10)}`;
};

export function isAllDigits(inputString: string): boolean {
  return /^\d+$/.test(inputString);
}

export function isNumericString(str: string): boolean {
  if (!str) return false;
  for (let i = 0; i < str?.length; i++) {
    if (isNaN(Number(str[i]))) {
      return false;
    }
  }
  return true;
}

export const sumServiceAndAddon = (service: ITableAddItem[], addon: ITableAddItem[]) => {
  const total =
    service.reduce((acc, item) => acc + Number(item.price || 0) * Number(item.qty || 1), 0) +
    addon.reduce((acc, item) => acc + Number(item.price || 0) * Number(item.qty || 1), 0);
  return total;
};

export function generateUniqueCode(length = 12) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const timestampPart = new Date().getTime().toString(36).toUpperCase(); // Dấu thời gian dưới dạng base-36
  const randomLength = Math.max(length - timestampPart.length, 0); // Độ dài còn lại cho phần ngẫu nhiên

  const randomPart = Array.from(
    { length: randomLength },
    () => characters[Math.floor(Math.random() * characters.length)],
  ).join('');

  return (timestampPart + randomPart).slice(0, length); // Đảm bảo mã đúng độ dài
}

export const handleCopyText = (str: string) => {
  try {
    navigator.clipboard.writeText(str);
    notification.success({ message: 'Copied successfully' });
  } catch (error) {
    const textArea = document.createElement('textarea');
    textArea.value = str;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    notification.success({ message: 'Copied successfully' });
  }
};

export function formatNumberFloat(value: number | null | undefined): string {
  if (typeof value !== 'number') {
    return '';
  }

  if (!Number.isInteger(value)) {
    const decimalPart = value.toString().split('.')[1] || '';
    if (decimalPart.length > 2) {
      return value.toFixed(2);
    }
  }

  return value.toString();
}

export const compressImage = (file: File, size: number | null): Promise<File> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      convertSize: size ? size : 1000000,
      maxWidth: 1000,
      maxHeight: 1000,
      success(result) {
        if (result instanceof File) {
          resolve(result);
        } else {
          const compressedFile = new File([result], file.name, { type: file.type });
          resolve(compressedFile);
        }
      },
      error(err) {
        reject(err);
      },
    });
  });
};
// check file if image > 1mb compress
export const handleCompressImage = async (file: File) => {
  if (file.size < 1000000) return file;
  const fileCompress = await compressImage(file, null);
  return fileCompress;
};

export const handleBase64AndCompress = async (base64Data: any, mimeType: string = ''): Promise<Blob> => {
  try {
    const originalFile = new File([base64Data], 'image', { type: mimeType });

    // Dùng handleCompressImage để nén ảnh
    const compressedFile = await handleCompressImage(originalFile);

    // Trả về Blob từ file đã nén
    return new Blob([compressedFile], { type: compressedFile.type });
  } catch (error) {
    throw new Error(`Error handling Base64 and compression: ${(error as Error).message}`);
  }
};

export const isAdmin = (profile: IProfile, isSalonOwner: boolean = false, isManager: boolean = false) => {
  if (profile?.role === UserRoleKey.ADMINISTRATOR || profile.isSuperAdmin) {
    return true;
  }
  if (isManager && profile?.role === UserRoleKey.MANAGER) {
    return true;
  }
  if (isSalonOwner && profile?.role === UserRoleKey.SALON_OWNER) {
    return true;
  }

  return false;
};

export function capitalizeText(text: string): string {
  return text
    .split(' ') // Split the string into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(' '); // Join the words back into a single string
}

export function generateRandomHexColors(count: number): string[] {
  const colors: Set<string> = new Set(); // Using Set to avoid duplicates

  while (colors.size < count) {
    const color = `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')}`;
    colors.add(color); // Add the color to the set (duplicates are automatically handled)
  }

  return Array.from(colors); // Convert Set to array
}

export const totalPointReceived = (totalPrice: Number, membership: IMembership | null) => {
  if (!membership || !membership.active) {
    return 0;
  }
  const exp = moment(membership?.extension_at).utc().toDate().getTime() + 365 * 24 * 60 * 60 * 1000;
  const now = new Date().getTime();
  if (now > exp) {
    return 0;
  }

  // Hệ số tích điểm
  const pointRatio = Number(membership?.category?.pointMultiplier || 0);

  return pointRatio * Number(totalPrice);
};
