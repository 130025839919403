import { MembershipApi } from '@/api-client/membership';
import MemberShipCard from '@/components/sections/membership-card';
import { membershipPolicy } from '@/data/membership-policy';
import { useAuth } from '@/hooks/auth.hook';
import { IMembership } from '@/interface/auth';
import { IMembershipCategories } from '@/interface/membership';
import { imgUrlTransferV1 } from '@/utils/global-func';
import { CheckCircleFilled } from '@ant-design/icons';
import { Checkbox, Divider, notification } from 'antd';
import Image from 'next/image';
import { Children, FC, useState } from 'react';
import PhoneCall from '../../../../../../public/images/phone-call.png';
import { MembershipDateLeft } from './date-left';

interface IMembershipDetailItem {
  item: IMembershipCategories;
  membershipCard?: IMembership;
}
export const MembershipDetailItem: FC<IMembershipDetailItem> = props => {
  const { item, membershipCard } = props;

  const { mutateProfile } = useAuth();
  const [loading, setLoading] = useState(false);

  const policy = membershipPolicy.find(v => v.key === item._id);

  return (
    <div className='flex flex-col gap-3'>
      <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Membership Detail</div>

      <MemberShipCard
        image={imgUrlTransferV1(item.image)}
        name={item.name}
        description={item.description}
        price={Number(item.price)}
        date={`${item.days_count}`}
        showCheckbox={false}
      />

      {membershipCard ? (
        <div className='flex flex-col gap-2'>
          <MembershipDateLeft
            name={membershipCard.category.name}
            total_date={membershipCard.category.days_count}
            extension_at={membershipCard.extension_at}
          />

          {!loading ? (
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row items-center gap-2'>
                <Checkbox
                  checked={membershipCard.is_recurring_enabled}
                  onChange={async () => {
                    if (loading) {
                      return;
                    }

                    setLoading(true);

                    try {
                      await MembershipApi.updateAutoRenewalCard(
                        membershipCard._id,
                        !membershipCard.is_recurring_enabled,
                      );
                      await mutateProfile();
                    } catch (err: any) {
                      notification.error({
                        message: 'cancel membership failed!',
                        description: `${err?.response?.data?.message}`,
                      });
                    } finally {
                      setLoading(false);
                    }
                  }}
                />
                <span className='font-hneu text-[12px] font-medium text-zelene-blue'>Auto Renewal</span>
              </div>

              <div className='flex flex-row items-stretch gap-2'>
                <div className='flex items-center justify-center text-2xl'>*</div>
                <div className='flex translate-y-1 items-center justify-center'>
                  <p className='font-hneu text-xs font-medium text-zelene-blue'>
                    Automatically renew the membership package
                  </p>
                </div>
              </div>

              {/* <div className='flex items-center justify-start gap-3'>
                <div className='relative flex size-[56px] items-center justify-center rounded-full bg-zelene-red'>
                  <span className='absolute inline-flex h-full w-full animate-customPing cursor-pointer items-center justify-center rounded-full opacity-75'>
                    <a href={`tel:4692071863`} target='_blank' rel='noopener noreferrer'>
                      <Image
                        src={PhoneCall}
                        alt='phone call'
                        width={72}
                        height={72}
                        className='w-auto object-contain'
                      />
                    </a>
                  </span>
                </div>

                <span className='flex flex-1 font-hneu font-semibold text-zelene-blue sm:text-base'>
                  Call Us to Learn About Membership Benefits
                </span>
              </div> */}
            </div>
          ) : (
            <span>process...</span>
          )}
        </div>
      ) : (
        <div></div>
      )}

      {/* {membershipCard ? (
        <Button
          size='large'
          className='!rounded-[44px] !border-zelene-red !bg-transparent !font-hneu !font-medium !text-zelene-red'
          onClick={async () => {
            if (loading) {
              return;
            }

            setLoading(true);

            try {
              await MembershipApi.deleteCard(membershipCard._id);
              await mutateProfile();
            } catch (err: any) {
              notification.error({
                message: 'cancel membership failed!',
                description: `${err?.response?.data?.message}`,
              });
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? 'loading' : 'Cancel Plan'}
        </Button>
      ) : (
        <Button
          size='large'
          className='!rounded-[44px] !border-zelene-yellow !bg-zelene-yellow !font-hneu !font-medium !text-white'
          onClick={async () => {
            if (loading) {
              return;
            }

            setLoading(true);

            try {
              await MembershipApi.createCard({ categoryId: item._id, is_recurring_enabled: true });
              await mutateProfile();
            } catch (err: any) {
              notification.error({
                message: 'register membership failed!',
                description: `${err?.response?.data?.message}`,
              });
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? 'loading' : 'Register Plan'}
        </Button>
      )} */}

      <div className='flex items-center justify-start gap-3'>
        <div className='relative flex size-[56px] items-center justify-center rounded-full bg-zelene-red'>
          <span className='absolute inline-flex h-full w-full animate-customPing cursor-pointer items-center justify-center rounded-full opacity-75'>
            <a href={`tel:4692071863`} target='_blank' rel='noopener noreferrer'>
              <Image src={PhoneCall} alt='phone call' width={72} height={72} className='w-auto object-contain' />
            </a>
          </span>
        </div>

        <span className='flex flex-1 font-hneu font-semibold text-zelene-blue sm:text-base'>
          Call Us to Learn About Membership Benefits
        </span>
      </div>

      <Divider className='!my-6' />

      <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Essential Plan Permission</div>

      <div className='flex flex-col gap-2 rounded-lg bg-white px-5 py-4'>
        <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Member Benefits</div>
        <div className='grid grid-cols-2 gap-[10px] pt-3 *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
          {Children.toArray(
            policy?.benefits.map(p => {
              return (
                <div className='flex gap-2'>
                  <CheckCircleFilled className='!text-green-700' />
                  <span>{p}</span>
                </div>
              );
            }),
          )}
        </div>
        <Divider className='!my-3' variant='dashed' />
        <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Member Pricing</div>
        <div className='grid grid-cols-2 gap-[10px] pt-3 *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
          {Children.toArray(
            policy?.pricing.map(p => {
              return (
                <div className='flex gap-2'>
                  <CheckCircleFilled className='!text-green-700' />
                  <span>{p}</span>
                </div>
              );
            }),
          )}
        </div>
      </div>
    </div>
  );
};
