'use client';

import Image from 'next/image';
import { forwardRef } from 'react';
import Background from '../../../../../../public/images/Home/Background element_Text.svg';

import Pattern from '../../../../../../public/images/Pattern.png';
import WhiteStar from '../../../../../../public/images/WhiteStar.png';
import LeftSVG from '../../../../../../public/svgs/LeftSVG.svg';
import RightSVG from '../../../../../../public/svgs/RightSVG.svg';
import BuyNowBtn from './BuyNowBtn';

interface Slider2Props {
  rightClick: () => void;
  leftClick: () => void;
  description: string;
  textMain: string;
  textMainSecond: string;
  imgMockup: string;
  imgSlider: string;
  textSlider: string;
  textCta: string;
  linkCta: string;
}

const Slider2 = forwardRef((props: Slider2Props, ref: any) => {
  const { rightClick, leftClick, description, textMain, textMainSecond, imgMockup, imgSlider, textSlider,textCta, linkCta } = props;

  return (
    <div ref={ref}>
      {/* mobile */}
      <div className='relative !w-full pb-6 pt-[132px] sm:hidden sm:pt-[192px]'>
        <div className='pl-6'>
          {/* border-radius: 22px 0px 0px 22px; */}
          <div className='relative h-[345px] w-full'>
            <Image
              src={imgSlider}
              alt={textSlider + ' image' || 'slider2 image'}
              className='!h-full !w-full rounded-bl-[22px] rounded-tl-[22px] object-cover'
              fill
              sizes='90vw'
            />
          </div>
          <div className='mt-6 flex items-center justify-end gap-3 px-6'>
            <Image
              onClick={leftClick}
              src={LeftSVG}
              alt='left'
              width={32}
              height={32}
              className='size-[32px] cursor-pointer object-contain'
            />

            <div className='font-hneu text-base font-semibold text-zelene-red'>{textSlider}</div>

            <Image
              onClick={rightClick}
              src={RightSVG}
              alt='right'
              width={32}
              height={32}
              className='size-[32px] cursor-pointer object-contain'
            />
          </div>
        </div>
        {/* 45 85/115 + pt 24 */}
        <div className='mx-auto flex w-[90%] flex-col gap-4'>
          <div className='flex flex-col'>
            <span className='font-[wise] text-[40px] font-medium text-zelene-dark-blue'>{textMain}</span>
            <div className='flex items-center gap-4'>
              <div className='relative size-[60px]'>
                <Image src={Pattern} alt='background image' fill sizes='60px' className='object-contain' />
              </div>

              <span className='font-[wise] text-[40px] font-medium text-zelene-dark-blue'>{textMainSecond}</span>

              <div className='relative size-10'>
                <Image src={WhiteStar} alt='background image' fill sizes='40px' className='object-contain' />
              </div>
            </div>
          </div>

          <span className='font-hneu text-[16px] text-[#3b5884]'>
            {/* Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift card. */}
            {description}
          </span>

          <div className='mt-[6px] flex items-center justify-between'>
            <BuyNowBtn
            routePath={linkCta}
            buttonText={textCta}
            />
          </div>
        </div>
      </div>
      {/* desktop */}
      <div className='relative hidden !h-[calc(100vh-48px)] !w-full bg-[#FFF4E4] sm:block'>
        <div className='absolute !left-0 bottom-0 !z-40 hidden w-full opacity-80 sm:block md:bottom-16 md:h-[180px]'>
          <Image
            width={600}
            height={180}
            alt='bg text'
            className='absolute h-auto w-[80%] object-contain md:w-[55%]'
            src={Background}
          />
        </div>

        <div className='!z-50 flex h-full w-full justify-between'>
          <div className='relative hidden flex-[7] bg-zelene-cream pt-[113px] md:flex lg:pt-[144px]'>
            <div className='absolute left-[15%] top-[212px] z-[60] flex w-full flex-col items-center justify-center md:left-1/2 lg:left-1/4'>
              <ContentSlider2 {...props} />
            </div>
          </div>

          {/* Right section */}
          <div className='relative z-10 my-20 mt-[45px] flex h-full w-full flex-[8] justify-start rounded-bl-[44px] rounded-tl-[44px] sm:h-full sm:!flex-[10] lg:!flex-[7]'>
            <Image
              src={imgMockup}
              alt='background image'
              className='!h-full !w-full object-cover object-center'
              fill
              sizes='80vw'
            />

            <div className='left-1/4 top-[30%] !z-30 flex w-full flex-col items-center justify-center md:hidden'>
              <div className='z-30 flex flex-col font-[wise] text-[clamp(3.5rem,-1.0832rem_+_9.6118vw,8rem)] font-medium text-zelene-dark-blue'>
                <span className='flex text-zelene-dark-blue'>
                  {textMain}{' '}
                  <Image
                    src={WhiteStar}
                    alt='background image'
                    width={70}
                    height={70}
                    className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[70px]'
                  />
                </span>

                <div className='z-30 -mt-8 flex w-full items-center gap-6'>
                  <Image
                    src={Pattern}
                    alt='background image'
                    width={113}
                    height={113}
                    className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[113px]'
                  />

                  <span className='font-[wise] text-[clamp(3.5rem,-1.0832rem_+_9.6118vw,8rem)] font-medium text-zelene-dark-blue sm:text-zelene-dark-blue'>
                    {/* Available */}
                    {textMainSecond}
                  </span>
                </div>

                <div className='max-w-[300px] pb-6 font-hneu text-base leading-[21.6px] text-zelene-dark-blue sm:text-[18px] sm:text-gray-800'>
                  {description}
                </div>

                <BuyNowBtn 

                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Slider2;

const ContentSlider2 = (props: Slider2Props) => {
  const { description, textMain, textMainSecond, textCta,linkCta } = props;
  return (
    <div className='z-50 flex flex-col font-[wise] font-medium text-zelene-dark-blue'>
      <div className='font-[wise] text-[clamp(3.8rem,-1.0832rem_+_9.6118vw,8rem)] leading-normal text-white sm:text-zelene-dark-blue'>
        {/* Giftcard is */}
        {textMain}
      </div>

      <div className='z-30 flex w-full items-center gap-6 lg:-mt-10'>
        <Image
          src={Pattern}
          alt='background image'
          width={113}
          height={113}
          className='!size-[40px] object-contain font-[wise] text-[128px] sm:!size-[60px] lg:!size-[113px]'
        />

        <div className='font-[wise] text-[clamp(3.8rem,-1.0832rem_+_9.6118vw,8rem)] leading-normal text-white sm:text-zelene-dark-blue'>
          {/* Available */}
          {textMainSecond}
        </div>

        <Image
          src={WhiteStar}
          alt='background image'
          width={70}
          height={70}
          className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[70px]'
        />
      </div>

      <div className='max-w-[300px] pb-6 pt-3 font-hneu text-base leading-[21.6px] text-zelene-slight-blue'>
        {description}
      </div>

      <BuyNowBtn
      routePath={linkCta}
       buttonText={textCta}/>
    </div>
  );
};
