'use client';

import { useRouter } from '@/hooks/useRouter';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const BuyNowBtn = ({ routePath = '/gift', buttonText = 'Gift Now' }) => {
  const router = useRouter();
  return (
    <Button
      onClick={() => router.push(routePath)}
      size='large'
      icon={<ArrowRightOutlined />}
      styles={{ icon: { order: 2 } }}
      className='!z-[70] w-fit cursor-pointer !rounded-xl !bg-[linear-gradient(180deg,_#102647_0%,_#1D3F75_100%)] !px-4 !py-3 !text-white hover:!bg-[#4674C6]'
    >
      {buttonText}
    </Button>
  );
};

export default BuyNowBtn;
