import { useCart } from '@/hooks/cart.hook';
import { CartListProps } from '@/interface/cart';
import React from 'react';
import CartItem from './CartItem';

const CartList: React.FC<CartListProps> = ({
  listCart,
  onRemove,
  location,
  showWarning,
  serviceConflict,
  setServiceConflict = [],
}) => {
  const { deleteCartMemberMutation } = useCart();

  return (
    <>
      {listCart?.map((cart: any) => {
        const mappingIds = serviceConflict?.map(item => item._ids).flat();
        const check = mappingIds?.some(item => item === undefined)
          ? serviceConflict?.map(item => item._id).flat()
          : mappingIds;

        const haveWarning = check?.includes(cart._id) || false;

        return (
          <CartItem
            showWarning={haveWarning}
            showAddonBtn={true}
            key={cart._id}
            cart={cart}
            onRemove={() => {
              deleteCartMemberMutation.mutate(cart._id);
              setServiceConflict([]);
            }}
          />
        );
      })}
    </>
  );
};

export default CartList;
